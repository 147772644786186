<template>
  <b-tabs class="hide-tabs">
    <b-tab
      v-for="(settingsTab, index) in tabs"
      :key="`settingsTab-${index}`"
    >
      <template #title>
        <material-icon
          :icon="settingsTab.icon"
          class="mr-50"
        />
        <span>{{ settingsTab.label }}</span>
      </template>

      <template v-if="value[settingsTab.key]">
        <dynamic-form-card
          v-for="(formSchema, formIndex) in settingsTab.forms"
          :key="`formSchema-${index}-${formIndex}`"
          :title="formSchema.title"
          :schema="formSchema.elements"
          :value="value[settingsTab.key]"
          @submit="payload => $emit('submit', payload)"
        />
      </template>
    </b-tab>
    <template #tabs-end>
      <b-nav-item
        v-if="addAction"
        role="presentation"
        @click.prevent="$emit('clickAdd')"
      >
        <material-icon
          icon="add"
          class="mr-50 mt-50 cursor-pointer"
        />
      </b-nav-item>
    </template>
  </b-tabs>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BTab, BTabs } from 'bootstrap-vue'
import DynamicFormCard from '@/views/components/forms/DynamicFormCard.vue'

export default {
  components: {
    DynamicFormCard,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
  },
  props: {
    tabs: {
      type: Array,
      default: Array,
    },
    value: {
      type: Object,
      required: true,
    },
    addAction: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    //
  },
  methods: {
  },
  setup(props, { emit }) {
    return {

    }
  },
}</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.modal-body label {
  font-size: unset;
}
.hide-tabs {
  .nav-tabs {
    display: none;
  }
}
</style>
