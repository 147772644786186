import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import currencyModel from '@/views/models/common/models/currencyModel'
import storeCurrencyModel from './storeCurrencyModel'

const {
  fetchList,
  fetchAll,
  fetchOne,
  create,
  update,
  deleteOne,
  deleteMany,
  sorting,
} = useModelStoreModule(storeCurrencyModel)

const {
  fetchList: fetchSystemCurrenciesList,
} = useModelStoreModule(currencyModel)

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList,
    fetchAll,
    fetchOne,
    create,
    update,
    deleteMany: deleteOne,
    sorting,

    fetchSystemCurrenciesList,
  },
}
