<template>
  <b-sidebar
    :id="id"
    ref="sidebar"
    :visible="isSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >

    <template #default="{ hide }">
      <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="secondary"
        opacity="0.75"
      >
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="itemId || itemData.id"
            class="mb-0"
          > <span v-t="`modules.store_currencies.pages.edit`" /></h5>
          <h5
            v-else
            v-t="`modules.store_currencies.pages.add`"
            class="mb-0"
          />

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Body -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div class="mb-2">
              <i-input
                id="parentCurrency"
                v-model="itemData.code"
                :errors="validationErrors.code"
                type="select"
                :options="currencyOptions"
                :reduce="cur => cur.id"
                title="modules.store_currencies.inputs.currency"
                :disabled="!!(itemId || itemData.id)"
              />
            </div>

            <div>
              <i-input
                v-model="itemData.auto_update_rate"
                :errors="validationErrors.auto_update_rate"
                type="switch"
                title="modules.store_currencies.inputs.auto_update_rate"
                description="modules.store_currencies.inputs.auto_update_rate:description"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </i-input>
            </div>

            <Transition name="fade-bottom">
              <div
                v-if="!itemData.auto_update_rate"
                class="mb-2"
              >
                <label for="basic-password1">{{ $t(`modules.store_currencies.inputs.exchange_rate`) }}</label>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`modules.store_currencies.inputs.exchange_rate`)"
                  :rules="validationErrors.auto_update_rate ? '': 'required'"
                >
                  <b-input-group
                    :append="selectedCurrency.value"
                    class="input-group-merge"
                  >
                    <template #prepend>
                      <b-input-group-text>1 {{ currentStoreCurrency.value }}</b-input-group-text>
                      <b-input-group-text class="px-1">
                        =
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="itemData.exchange_rate"
                      placeholder="10"
                      type="number"
                      step="0.0000000001"
                      min="0"
                      title="modules.store_currencies.inputs.exchange_rate"
                      :required="!itemData.auto_update_rate"
                      :disabled="itemData.auto_update_rate"
                      class="px-1"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </Transition>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-t="(itemId || itemData.id)?`Edit`:`Add`"
                variant="primary"
                class="mr-2"
                type="submit"
              />
              <b-button
                v-t="`Cancel`"
                variant="outline-secondary"
                @click="hide"
              />
            </div>
          </b-form>
        </validation-observer>

      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BOverlay, BFormInput, BInputGroup, BInputGroupText,
} from 'bootstrap-vue'
import {
  computed, onMounted, onUnmounted, ref,
} from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import pageStoreModule from '@/views/models/pages/pageStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { getLocale } from '@core/utils/utils'
import storeCurrencyStoreModule from '@/views/models/store_currencies/storeCurrencyStoreModule'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    BInputGroupText,
    BInputGroup,
    BFormInput,
    ValidationObserver,
    BSidebar,
    BForm,
    BButton,
    BOverlay,
  },
  directives: {
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      default: 'sidebar-add-new-category',
      required: false,
    },
    itemId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      required,
    }
  },
  watch: {
    itemId(newValue) {
      if (newValue) {
        this.isLoading = true
        this.fetchItem(newValue).finally(() => {
          this.isLoading = false
        })
      }
    },
    isSidebarActive(newValue) {
      if (newValue) {
        if (this.itemId) {
          this.fetchItem(newValue).finally(() => {
            this.isLoading = false
          })
        }
        if (!this.currencyOptions.length) {
          this.fetchOptions()
        }
      } else {
        this.resetForm()
      }
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: { getLocale },
  emits: ['refetch-data'],
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'store_currencies'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeCurrencyStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, pageStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Data definition
    const refs = ref({})
    const emptyItemData = {
      code: null,
      auto_update_rate: true,
      exchange_rate: null,
    }
    const itemData = ref({})
    const isLoading = ref(true)
    const currencyOptions = ref([])
    const currentStoreCurrency = ref({})

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(emptyItemData))
      isLoading.value = false
      ctx.emit('update:item-id', null)
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const { validationErrors, fetch, save } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      () => {
        ctx.emit('refetch-data')
        ctx.emit('update:is-sidebar-active', false)
      },
      null,
      null,
      () => {
        // refs.value.value.sidebar.hide()
        itemData.value = JSON.parse(JSON.stringify(emptyItemData))

        ctx.emit('update:is-sidebar-active', false)
      },
    )

    const selectedCurrency = computed(() => {
      if (!currencyOptions.value.length || !itemData.value.code) {
        return {}
      }
      const selectedCurrencyCode = typeof (itemData.value) === 'string' ? itemData.value : itemData.value.code
      return currencyOptions.value.find(currency => currency.id === selectedCurrencyCode) || {}
    })

    const fetchItem = () => {
      if (itemData.value.id !== props.itemId) {
        itemData.value.id = props.itemId
        return fetch()
      }
      return Promise.resolve()
    }

    const fetchOptions = () => {
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchSystemCurrenciesList`, { not_used: true, with_store_currency: true }).then(response => {
        currencyOptions.value = response.data.data
        currentStoreCurrency.value = response.data.store_currency
      }).finally(() => {
        isLoading.value = false
      })
    }

    const onSubmit = () => {
      isLoading.value = true
      save(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    return {
      itemData,
      currentStoreCurrency,
      currencyOptions,
      selectedCurrency,
      fetchItem,
      validationErrors,
      onSubmit,
      fetchOptions,

      refFormObserver,
      getValidationState,
      resetForm,

      refs,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
