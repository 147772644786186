<template>
  <div>
    <b-card>
      <div class="design-group">
        <h6 class="section-label">
          {{ $t('modules.store_currencies._cards.store_currency') }}
        </h6>
        <b-card-text
          v-if="settings.store_currency"
          class="mb-2"
        >
          {{ settings.store_currency.label }} ({{ settings.store_currency.symbol || settings.store_currency.value }})
        </b-card-text>
        <b-badge
          v-else
          pill
          variant="light-secondary"
          style="width: 120px; height: 21px"
        >&nbsp;</b-badge>
      </div>
    </b-card>

    <div
      v-if="!showDatatable"
      class="d-flex justify-content-center text-center"
    >
      <span
        class="text-success cursor-pointer"
        @click="onLockedFeatureClick"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
          size="18"
        />
        <span>{{ $t('modules.store_currencies.actions.add_additional_currencies') }}</span>
        <material-icon
          v-if="!featureAvailability.is_available"
          icon="crown"
          class="ml-50 text-warning"
          size="18"
        />
      </span>
    </div>
    <!-- Table Container Card -->
    <b-card
      v-show="showDatatable"
      no-body
    >
      <b-card-body class="pb-0">
        <h6 class="section-label">
          {{ $t('modules.store_currencies._cards.additional_currencies') }}
        </h6>
      </b-card-body>
      <model-list-table
        :store-module-name="storeModuleName"
        :table-columns="tableColumns"
        :filters-list="filtersList"
        :query-params="getQueryParams"
        add-action="sidebar"
        :view-action="false"
        :preview-action="false"
        edit-action="sidebar"
        :delete-action="true"
        :extra-actions="[]"
        :sidebar="SidebarAddNewStoreCurrency"
        :is-sortable="false"
        @items-count="v => additionalCurrenciesCount = v"
      />
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import Vue, {
  ref, onUnmounted, onMounted, watch, computed,
} from 'vue'
import ModelListTable from '@/views/models/common/ModelListTable.vue'
import SidebarAddNewStoreCurrency from '@/views/models/store_currencies/add/SidebarAddNewStoreCurrency.vue'
import {
  BBadge,
  BCard, BCardBody, BCardText, VBToggle,
} from 'bootstrap-vue'
import { t } from '@core/libs/i18n/utils'
import Router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { toast } from '@core/utils/utils'
import purchaseStoreModule from '@/views/models/purchases/purchaseStoreModule'
import storeCurrencyStoreModule from '../storeCurrencyStoreModule'

export default {
  components: {
    BCardBody,
    BBadge,
    BCardText,
    BCard,
    ModelListTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },

  props: {
    settings: {
      type: Object,
      default: Object,
    },
  },
  methods: {
  },
  setup(props) {
    const STORE_MODULE_NAME = 'store_currencies'
    const PURCHASES_STORE_MODULE_NAME = 'purchases'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeCurrencyStoreModule)
    if (!store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.registerModule(PURCHASES_STORE_MODULE_NAME, purchaseStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeCurrencyStoreModule)
      if (!store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.registerModule(PURCHASES_STORE_MODULE_NAME, purchaseStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
      if (store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.unregisterModule(PURCHASES_STORE_MODULE_NAME)
    })

    // const categoryFilter = ref(null)
    const statusFilter = ref(null)
    const showDatatable = ref(false)
    const additionalCurrenciesCount = ref(0)
    const isFeatureModalVisible = ref(false)

    const tableColumns = [
      // {
      //   key: 'draggable',
      //   label: '',
      //   sortable: false,
      //   thClass: 'p-0',
      //   thStyle: 'width: 1px',
      //   tdClass: ['p-0', 'pl-1', 'draggable-handle', 'cursor-move'],
      // },
      {
        key: 'checkbox',
        label: '',
        sortable: false,
      },
      { key: 'name', sortable: true, label: 'modules.store_currencies._columns.currency' },
      {
        key: 'exchange_rate',
        label: 'modules.store_currencies._columns.exchange_rate',
        sortable: true,
      },
      // {
      //   key: 'updated_at',
      //   label: 'Last Update',
      //   formatter: formatDate,
      //   sortable: true,
      // },
      // { key: 'is_active', sortable: true, label: 'Status' },
      { key: 'actions' },
    ]

    const filtersList = [/* statusFilter */]
    const getQueryParams = () => ({
      // categories: categoryFilter.value,
      is_active: statusFilter.value,
    })

    const featureAvailability = computed(() => props.settings.feature_availability || {})
    // const swalVariantsOptions = computed(() => {
    //   return featureAvailability.value.variants.map(variant => {
    //     return `<button type="button" class="btn btn-primary btn-block">${variant.label}</button>`
    //   })
    // })
    const swalButtonOne = computed(() => {
      if (!featureAvailability?.value?.variants?.length) {
        return null
      }
      return featureAvailability.value.variants[0]
    })

    const swalButtonTwo = computed(() => {
      if (!featureAvailability?.value?.variants?.length || featureAvailability?.value?.variants?.length < 2) {
        return null
      }
      return featureAvailability.value.variants[1]
    })

    const isLoading = ref(false)
    const onAddToCartClick = purchasableCode => {
      if (isLoading.value) {
        return
      }
      isLoading.value = true
      store.dispatch(`${PURCHASES_STORE_MODULE_NAME}/updateAttribute`, {
        itemId: 'cart',
        attribute: 'items',
        value: [{
          id: purchasableCode,
          qty: 1,
        }],
      })
        .then(response => {
          Router.push({ name: 'purchases.checkout' })
        })
        .catch(e => {
          const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'
          console.error(e)
          toast('danger', title, '', 'AlertTriangleIcon')
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    const onLockedFeatureClick = () => {
      if (featureAvailability.value.is_available) {
        showDatatable.value = true
        return
      }
      Vue.swal({
        title: t('errors.featured_locked.title'),
        text: t('errors.featured_locked.text'),
        icon: 'warning',
        showCloseButton: true,
        showConfirmButton: !!swalButtonOne.value,
        showDenyButton: !!swalButtonTwo.value,
        showCancelButton: true,
        iconHtml: '<span style=" font-size: 24pt; " class="material-symbols-outlined text-warning">crown</span>',
        // footer: '<div class="d-block w-100">'
        //     + swalVariantsOptions.value.join('')
        //     + '<button type="button" class="btn btn-outline-danger btn-block">' + t('Cancel') + '</button>'
        //     + '</div>',
        confirmButtonText: swalButtonOne.value?.label || '',
        denyButtonText: swalButtonTwo.value?.label || '',
        cancelButtonText: t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-success btn-block',
          denyButton: 'btn btn-success btn-block',
          cancelButton: 'btn btn-outline-danger btn-block',
          footer: '',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed && swalButtonOne.value) {
          onAddToCartClick(swalButtonOne.value.code)
        } else if (result.isDenied && swalButtonTwo.value) {
          onAddToCartClick(swalButtonTwo.value.code)
        }
      })
    }

    watch(additionalCurrenciesCount, newValue => {
      if (newValue > 0) {
        showDatatable.value = true
      }
    })

    return {
      // Extra Filters
      statusFilter,
      showDatatable,
      additionalCurrenciesCount,
      featureAvailability,
      isFeatureModalVisible,
      swalButtonOne,
      swalButtonTwo,

      // Table props
      tableColumns,
      storeModuleName: STORE_MODULE_NAME,
      filtersList,
      getQueryParams,

      SidebarAddNewStoreCurrency,
      onLockedFeatureClick,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">

$gray-300: #dee2e6 !default;
$border-color: $gray-300 !default;

::v-deep .b-table {
  tbody {
    tr {
      //cursor: move;
    }
  }
}

.b-table {
  tbody {
    tr.ready-for-drag {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 $border-color;
      transition: all 0.2s;
    }
    tr {
      &:has(td .draggable-handle) {
        transform: translateY(-4px);
        box-shadow: 0 3px 10px 0 $border-color;
        transition: all 0.2s;
      }
    }
  }
}

.btn-link:hover {
  color: #5d6064 !important;
}
</style>
